import { Document, Image, PDFDownloadLink, Page, StyleSheet, Text, View, pdf, PDFViewer } from '@react-pdf/renderer';
import axios from 'axios';
import fileDownload from 'js-file-download';
import React, { Component } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ca_header from '../../media/ca_header.png';
import us_header from '../../media/us_header.png';
import { sendPostFetch } from './FetchFunctions';

const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
            resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};

const styles = StyleSheet.create({
    document: {
        display: 'flex',
        direction: 'column',
        padding: '10',
    },
    page: {
        display: 'flex',
        direction: 'column',
        padding: '10',
    },
    header_section: {
        display: 'flex',
        direction: 'column',
        padding: '5',
    },
    section: {
        display: 'flex',
        direction: 'column',
        padding: '5',
    },
    section_multi: {
        display: 'flex',
        flexDirection: 'column',
        padding: '5',
        margin: '5',
        justifyContent: 'start',
        border: '2px solid gray',
        borderRadius: '0.5vw',
    },
    title: {
        fontSize: '16px',
        fontWeight: '600',
        textAlign: 'center',
        margin: '10',
    },
    sub_title: {
        fontSize: '12px',
        fontWeight: '600',
        textAlign: 'left',
        marginBottom: '10',
        lineHeight: '0.2vw',
    },
    sub_title_red_center: {
        fontSize: '13px',
        fontWeight: '600',
        textAlign: 'center',
        color: 'red',
        marginBottom: '10',
        lineHeight: '0.2vw',
    },
    sub_title_big: {
        fontSize: '13px',
        fontWeight: '600',
        textAlign: 'left',
        marginBottom: '10',
        lineHeight: '0.2vw',
    },
    form_field: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '80%',
        padding: '10',
        alignSelf: 'center',
    },
    form_field_col: {
        flexDirection: 'column',
        justifyContent: 'left',
        width: '80%',
        padding: '10',
        alignSelf: 'center',
    },

    form_field_img: {
        flexDirection: 'column',
        justifyContent: 'start',
        width: '100%',
        padding: '10',
        alignSelf: 'center',
        border: '2px solid gray',
        borderRadius: '0.5vw',
        margin: '10',
    },
    form_text: {
        fontSize: '12px',
        textAlign: 'left',
        width: '44%',
        lineHeight: '0.2vw',
    },
    form_answer: {
        fontSize: '12px',
        textAlign: 'center',
        paddingLeft: '10',
        paddingRight: '10',
        borderBottom: '2px solid gray',
        width: '55%',
        color: '#757575',
        lineHeight: '0.2vw',
    },
    form_text_long: {
        fontSize: '12px',
        textAlign: 'left',
        width: '100%',
        lineHeight: '0.2vw',
    },
    form_answer_long: {
        fontSize: '12px',
        textAlign: 'left',
        paddingLeft: '10',
        paddingRight: '10',
        borderBottom: '2px solid gray',
        width: '100%',
        color: '#757575',
        marginTop: '10',
        lineHeight: '0.2vw',
    },
    image_title: {
        fontSize: '12px',
        textAlign: 'center',
        width: '100%',
        color: '#757575',
    },
    header: {
        fontSize: '2.5vw',
        fontWeight: '600',
        textAlign: 'center',
    },
    spacer: {
        margin: '10',
    },
    small_spacer: {
        margin: '6',
    },
    response: {
        fontSize: '2vw',
        textAlign: 'center',
        alignSelf: 'center',
        color: 'red',
        width: '100%',
    },
});
const header_image_style = {
    width: '100%',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
};
const signature_style = {
    width: '80%',
    padding: '10',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
};
const datetime_style = {
    fontSize: '12px',
    textAlign: 'center',
    padding: '5',
};
const general_attachment_style = {
    width: '80%',
    padding: '10',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
};

export class Submit extends Component {
    constructor(props) {
        super(props);
        this.state = { sending: false };
        this.handleDownload = this.handleDownload.bind(this);
        this.sendApplication = this.sendApplication.bind(this);
        this.addDocument = this.addDocument.bind(this);
    }

    handleDownload = (url, filename) => {
        console.log(url);
        axios
            .get(url, {
                responseType: 'blob',
            })
            .then((res) => {
                fileDownload(res.data, filename);
            });
    };

    addDocument() {
        const currentApplication = this.props.current_application;
        currentApplication.Status_id = 'Application Submitted';
        currentApplication.Date_Submitted = new Date();
        this.props.onPost(currentApplication);
    }

    async sendApplication(application_doc) {
        if (this.props.current_application.Full_Name === '') {
            return toast.error('Please complete all fields in Personal History before submitting application.');
        } else {
            try {
                const blobPdf = await pdf(application_doc).toBlob();
                this.setState({ sending: true });
                const body = {
                    App_id: this.props.current_application.Id,
                    Full_Name: this.props.current_application.Full_Name,
                    Email: this.props.current_application.Email,
                    Primary_Phone: this.props.personal_history.Cell_Phone,
                    Country: this.props.current_application.Country,
                    Position: this.props.general_info.Position_Applying_For
                        ? this.props.general_info.Position_Applying_For
                        : 'No Position Selected',
                    File_Bit_Array: '',
                    Recruiter: this.props.current_application.Reviewed_By,
                };
                await convertBase64(blobPdf).then((res) => {
                    body.File_Bit_Array = res.replaceAll('data:application/pdf;base64,', '');
                });
                await sendPostFetch(JSON.stringify(body), 'mailgun/SubmitApplication').then((data) => {
                    sendPostFetch(JSON.stringify(body), 'mailgun/ApplicantConfirmation').then((res) => {
                        this.setState({ sending: false });
                        toast.success('Application Submitted');
                        this.addDocument();
                    });
                });
            } catch (err) {
                this.setState({ sending: false });
                toast.error(err.response && err.response.data.message ? err.response.data.message : err.message);
            }
        }
    }

    render() {
        const { sending } = this.state;

        //#region Company Info
        const current_country = this.props.current_application.Country;
        const address =
            current_country === 'CA'
                ? '1150 Vittoria Rd. R.R. #1, Vittoria, ON N0E 1W0'
                : '9597 Gulf Research Lane, Fort Myers, FL 33912';
        //let phone = current_country === 'CA' ? '(519) 426-2700' :'(239) 471-3614';
        // let fax = current_country === 'CA' ? '(519) 426-2647' : '(239) 471-3133';
        //#endregion Company Info

        //#region PDF Header
        const pdf_header =
            current_country === 'CA' ? (
                <View style={styles.header_section}>
                    <Image src={ca_header} style={header_image_style} />
                </View>
            ) : (
                <View style={styles.header_section}>
                    <Image src={us_header} style={header_image_style} />
                </View>
            );
        //#endregion PDF Header

        //#region General Info

        //#region General Info Attachments

        const license_image = this.props.driver_license_file ? (
            <Image src={this.props.driver_license_file + '?raw=true'} style={general_attachment_style} />
        ) : null;
        const license_image_back = this.props.driver_license_file_back ? (
            <Image src={this.props.driver_license_file_back + '?raw=true'} style={general_attachment_style} />
        ) : null;
        const truck_front = this.props.truck_front_file ? (
            <Image src={this.props.truck_front_file + '?raw=true'} style={general_attachment_style} />
        ) : null;
        const truck_back = this.props.truck_back_file ? (
            <Image src={this.props.truck_back_file + '?raw=true'} style={general_attachment_style} />
        ) : null;
        const truck_left = this.props.truck_left_file ? (
            <Image src={this.props.truck_left_file + '?raw=true'} style={general_attachment_style} />
        ) : null;
        const truck_right = this.props.truck_right_file ? (
            <Image src={this.props.truck_right_file + '?raw=true'} style={general_attachment_style} />
        ) : null;
        //#endregion General Info Attachments

        const general_info_page = this.props.general_info ? (
            <Page size="A4" style={styles.page}>
                {pdf_header}
                <View style={styles.section}>
                    <Text style={styles.title}>General Information</Text>
                </View>
                <View style={styles.form_field}>
                    <Text style={styles.form_text}>Email Address:</Text>
                    <Text style={styles.form_answer}>{this.props.current_application.Email}</Text>
                </View>
                <View style={styles.form_field}>
                    <Text style={styles.form_text}>How did you hear about Scotlynn?</Text>
                    <Text style={styles.form_answer}>{this.props.general_info.Hear_About}</Text>
                </View>
                {this.props.general_info.Hear_About === 'Other' ? (
                    <View style={styles.form_field}>
                        <Text style={styles.form_text}>Other:</Text>
                        <Text style={styles.form_answer}>{this.props.general_info.Other_Details}</Text>
                    </View>
                ) : null}
                {this.props.general_info.Hear_About === 'Referral' ? (
                    <View style={styles.form_field}>
                        <Text style={styles.form_text}>Referred Employee:</Text>
                        <Text style={styles.form_answer}>{this.props.general_info.Referred_Employee}</Text>
                    </View>
                ) : null}

                <View style={styles.form_field}>
                    <Text style={styles.form_text}>Position Applying For: </Text>
                    <Text style={styles.form_answer}>{this.props.general_info.Position_Applying_For}</Text>
                </View>
                <View style={styles.form_field}>
                    <Text style={styles.form_text}>Driver's License Number: </Text>
                    <Text style={styles.form_answer}>{this.props.general_info.License_Number}</Text>
                </View>
                <View style={styles.form_field}>
                    <Text style={styles.form_text}>Driver's License State/Province: </Text>
                    <Text style={styles.form_answer}>{this.props.general_info.License_State}</Text>
                </View>
                <View style={styles.form_field_img} wrap={false}>
                    <Text style={styles.image_title}>License Copy (front)</Text>
                    {license_image}
                </View>
                <View style={styles.form_field_img} wrap={false}>
                    <Text style={styles.image_title}>License Copy (back)</Text>
                    {license_image_back}
                </View>
            </Page>
        ) : null;

        //#endregion General Info

        //#region Truck Info
        const truck_info_page =
            this.props.general_info.Position_Applying_For === 'Owner Operator' ? (
                <Page size="A4" style={styles.page}>
                    <View style={styles.section}>
                        <Text style={styles.title}>Truck Information</Text>
                    </View>
                    <View style={styles.form_field}>
                        <Text style={styles.form_text}>Truck Make</Text>
                        <Text style={styles.form_answer}>{this.props.truck_info.Make}</Text>
                    </View>
                    <View style={styles.form_field}>
                        <Text style={styles.form_text}>Truck Model</Text>
                        <Text style={styles.form_answer}>{this.props.truck_info.Model}</Text>
                    </View>
                    <View style={styles.form_field}>
                        <Text style={styles.form_text}>Truck Year</Text>
                        <Text style={styles.form_answer}>{this.props.truck_info.Year}</Text>
                    </View>
                    <View style={styles.form_field}>
                        <Text style={styles.form_text}>Transmission Capability</Text>
                        <Text style={styles.form_answer}>{this.props.truck_info.Transmission_Capability}</Text>
                    </View>
                    <View style={styles.form_field}>
                        <Text style={styles.form_text}>Daycab/Bunk</Text>
                        <Text style={styles.form_answer}>{this.props.truck_info.Daycab_Bunk}</Text>
                    </View>
                    <View style={styles.form_field_img} wrap={false}>
                        <Text style={styles.image_title}>Truck (front)</Text>
                        {truck_front}
                    </View>
                    <View style={styles.form_field_img} wrap={false}>
                        <Text style={styles.image_title}>Truck (back)</Text>
                        {truck_back}
                    </View>
                    <View style={styles.form_field_img} wrap={false}>
                        <Text style={styles.image_title}>Truck (right)</Text>
                        {truck_right}
                    </View>
                    <View style={styles.form_field_img} wrap={false}>
                        <Text style={styles.image_title}>Truck (left)</Text>
                        {truck_left}
                    </View>
                </Page>
            ) : null;
        //#endregion Truck Info

        //#region Personal Info
        const personal_info_page =
            this.props.personal_history !== undefined ? (
                <View style={styles.section} wrap={false}>
                    <View style={styles.section}>
                        <Text style={styles.title}>Personal History</Text>
                    </View>
                    <View style={styles.form_field}>
                        <Text style={styles.form_text}>Full Name</Text>
                        <Text style={styles.form_answer}>{this.props.personal_history.Full_Name}</Text>
                    </View>
                    <View style={styles.form_field}>
                        <Text style={styles.form_text}>Street Address</Text>
                        <Text style={styles.form_answer}>{this.props.personal_history.Street_Address}</Text>
                    </View>
                    <View style={styles.form_field}>
                        <Text style={styles.form_text}>City</Text>
                        <Text style={styles.form_answer}>{this.props.personal_history.City}</Text>
                    </View>
                    <View style={styles.form_field}>
                        <Text style={styles.form_text}>State/Province</Text>
                        <Text style={styles.form_answer}>{this.props.personal_history.Province}</Text>
                    </View>
                    <View style={styles.form_field}>
                        <Text style={styles.form_text}>Postal Code</Text>
                        <Text style={styles.form_answer}>{this.props.personal_history.Postal_Code}</Text>
                    </View>
                    <View style={styles.form_field}>
                        <Text style={styles.form_text}>Cell Phone</Text>
                        <Text style={styles.form_answer}>{this.props.personal_history.Cell_Phone}</Text>
                    </View>
                    <View style={styles.form_field}>
                        <Text style={styles.form_text}>Date of Birth</Text>
                        <Text style={styles.form_answer}>{this.props.personal_history.Date_of_Birth}</Text>
                    </View>
                    <View style={styles.form_field}>
                        <Text style={styles.form_text}>SIN/SSN</Text>
                        <Text style={styles.form_answer}>
                            ***-**-
                            {this.props.personal_history.SSN.substring(this.props.personal_history.SSN.length - 4)}
                        </Text>
                    </View>
                    <View style={styles.form_field}>
                        <Text style={styles.form_text}>Marital Status</Text>
                        <Text style={styles.form_answer}>{this.props.personal_history.Marital_Status}</Text>
                    </View>
                </View>
            ) : null;
        //#endregion Personal Info

        //#region Emergency Contact
        const emergency_contact_info =
            this.props.emergency_contact !== undefined ? (
                <View style={styles.section} wrap={false}>
                    <View style={styles.section}>
                        <Text style={styles.title}>Emergency Contact</Text>
                    </View>
                    <View style={styles.form_field}>
                        <Text style={styles.form_text}>Full Name</Text>
                        <Text style={styles.form_answer}>{this.props.emergency_contact.Full_Name}</Text>
                    </View>
                    <View style={styles.form_field}>
                        <Text style={styles.form_text}>Phone</Text>
                        <Text style={styles.form_answer}>{this.props.emergency_contact.Phone}</Text>
                    </View>

                    <View style={styles.form_field}>
                        <Text style={styles.form_text}>Relationship</Text>
                        <Text style={styles.form_answer}>{this.props.emergency_contact.Relationship}</Text>
                    </View>
                </View>
            ) : null;
        //#endregion Emergency Contact info

        //#region Physical
        const physical_info =
            this.props.physical_history !== undefined ? (
                <View style={styles.section} wrap={false}>
                    <View style={styles.section}>
                        <Text style={styles.title}>Physical History</Text>
                    </View>

                    <View style={styles.form_field_col}>
                        <Text style={styles.form_text}>Capable of manual work:</Text>
                        <Text style={styles.form_answer_long}>
                            {this.props.physical_history.Manual_Work === 'N'
                                ? 'No'
                                : this.props.physical_history.Manual_Work === ''
                                  ? ''
                                  : 'Yes'}
                        </Text>
                    </View>
                    {this.props.physical_history.Manual_Work === 'N' ? null : (
                        <View style={styles.form_field_col}>
                            <Text style={styles.form_text}>Explanation for "No":</Text>
                            <Text style={styles.form_answer_long}>
                                {this.props.physical_history.Manual_Work_Reason}
                            </Text>
                        </View>
                    )}

                    <View style={styles.form_field_col}>
                        <Text style={styles.form_text}>Have been injured on the job:</Text>
                        <Text style={styles.form_answer_long}>
                            {this.props.physical_history.Injured_On_Job === 'N'
                                ? 'No'
                                : this.props.physical_history.Injured_On_Job === ''
                                  ? ''
                                  : 'Yes'}
                        </Text>
                    </View>
                    {this.props.physical_history.Injured_On_Job === 'N' ? null : (
                        <View style={styles.form_field_col}>
                            <Text style={styles.form_text}>Explanation for "Yes":</Text>
                            <Text style={styles.form_answer_long}>{this.props.physical_history.Injured_Reason}</Text>
                        </View>
                    )}

                    <View style={styles.form_field_col}>
                        <Text style={styles.form_text}>Other Issues:</Text>
                        <Text style={styles.form_answer_long}>{this.props.physical_history.Other_Issues}</Text>
                    </View>
                </View>
            ) : null;
        //#endregion Physical

        //#region Employers

        const employers = this.props.employers ? (
            <Page style={styles.page}>
                <View style={styles.section}>
                    <Text style={styles.title}>Employment History</Text>
                </View>
                {this.props.current_application ? (
                    <View style={styles.section}>
                        <Text style={styles.sub_title}>
                            [{this.props.current_application.Employment_Completed === 'Y' ? 'X' : ' '}] Filled out
                            10 years of previous employment history
                        </Text>
                        <Text style={styles.sub_title}>
                            [{this.props.current_application.Employment_Completed === 'U' ? 'X' : ' '}] Has been
                            unemployed or in school for the last 10 years
                        </Text>
                    </View>
                ) : null}

                {this.props.employers.map((d, index) => (
                    <View style={styles.section_multi} wrap={false}>
                        <View style={styles.section}>
                            <Text style={styles.sub_title}>Employment History #{index + 1}</Text>
                        </View>
                        <View style={styles.form_field}>
                            <Text style={styles.form_text}>Employer's Name:</Text>
                            <Text style={styles.form_answer}>{d.Employer_Name}</Text>
                        </View>
                        <View style={styles.form_field}>
                            <Text style={styles.form_text}>Address:</Text>
                            <Text style={styles.form_answer}>{d.Employer_Address}</Text>
                        </View>
                        <View style={styles.form_field}>
                            <Text style={styles.form_text}>Contact:</Text>
                            <Text style={styles.form_answer}>{d.Contact_Name}</Text>
                        </View>
                        <View style={styles.form_field}>
                            <Text style={styles.form_text}>Phone:</Text>
                            <Text style={styles.form_answer}>{d.Phone}</Text>
                        </View>
                        <View style={styles.form_field}>
                            <Text style={styles.form_text}>Commericial Driver:</Text>
                            <Text style={styles.form_answer}>{d.Position}</Text>
                        </View>
                        <View style={styles.form_field}>
                            <Text style={styles.form_text}>Current Employer:</Text>
                            <Text style={styles.form_answer}>
                                {d.Current_Employer === 'Y' ? 'Yes' : d.Current_Employer === '' ? '' : 'No'}
                            </Text>
                        </View>
                        <View style={styles.form_field}>
                            <Text style={styles.form_text}>Start Date:</Text>
                            <Text style={styles.form_answer}>{d.Hire_Date}</Text>
                        </View>
                        {d.Current_Employer === 'Y' ? null : (
                            <View style={styles.form_field}>
                                <Text style={styles.form_text}>End Date:</Text>
                                <Text style={styles.form_answer}>{d.Terminate_Date}</Text>
                            </View>
                        )}
                        {d.Current_Employer === 'Y' ? null : (
                            <View style={styles.form_field_col}>
                                <Text style={styles.form_text}>Reason For Leaving:</Text>
                                <Text style={styles.form_answer_long}>{d.Reason_For_Leaving}</Text>
                            </View>
                        )}
                    </View>
                ))}

                {this.props.employment_gaps.map((d, index) => (
                    <View style={styles.section_multi} wrap={false}>
                        <View style={styles.section}>
                            <Text style={styles.sub_title}>Non-Employment Period #{index + 1}</Text>
                        </View>
                        <View style={styles.form_field}>
                            <Text style={styles.form_text}>Start Date:</Text>
                            <Text style={styles.form_answer}>{d.Start_Date}</Text>
                        </View>
                        <View style={styles.form_field}>
                            <Text style={styles.form_text}>End Date:</Text>
                            <Text style={styles.form_answer}>{d.End_Date}</Text>
                        </View>
                        <View style={styles.form_field_col}>
                            <Text style={styles.form_text}>Reason:</Text>
                            <View style={styles.spacer} />
                            <Text style={styles.form_answer_long}>{d.Reason}</Text>
                        </View>
                    </View>
                ))}
            </Page>
        ) : null;
        //#endregion Employers

        //#region License Info
        const license =
            this.props.license_info !== undefined ? (
                <View style={styles.section} wrap={false}>
                    <View style={styles.section}>
                        <Text style={styles.title}>License Information</Text>
                    </View>

                    <View style={styles.form_field_col}>
                        <Text style={styles.form_text_long}>
                            Have you ever been denied a license, permit or privilege to operate a motor vehicle?
                        </Text>
                        <Text style={styles.form_answer_long}>
                            {this.props.license_info.Denied_License === 'N'
                                ? 'No'
                                : this.props.license_info.Denied_License === ''
                                  ? ''
                                  : 'Yes'}
                        </Text>
                    </View>
                    <View style={styles.form_field_col}>
                        <Text style={styles.form_text_long}>
                            Has a license, permit or privilege been suspended or revoked?
                        </Text>
                        <Text style={styles.form_answer_long}>
                            {this.props.license_info.Suspended === 'N'
                                ? 'No'
                                : this.props.license_info.Suspended === ''
                                  ? ''
                                  : 'Yes'}
                        </Text>
                    </View>
                    {this.props.license_info.Suspended === 'N' ? null : (
                        <View style={styles.form_field_col}>
                            <Text style={styles.form_text_long}>
                                If you have answered "Yes" to any of the above questions, please describe in detail:
                            </Text>
                            <Text style={styles.form_answer_long}>{this.props.license_info.Reason_For_Yes}</Text>
                        </View>
                    )}
                </View>
            ) : null;

        //#endregion License Info

        //#region Experience
        let regions_string = '';
        for (let i = 0; i < this.props.experience.Regions.length; i++) {
            if (regions_string === '') {
                regions_string = this.props.experience.Regions[i];
            } else {
                regions_string = regions_string + ', ' + this.props.experience.Regions[i];
            }
        }

        const experience_info =
            this.props.experience !== undefined ? (
                <View style={styles.section} wrap={false}>
                    <View style={styles.section}>
                        <Text style={styles.title}>Experience</Text>
                    </View>
                    <View style={styles.form_field_col}>
                        <Text style={styles.form_text_long}>Do you drive Automatic or Manual Transmission?</Text>
                        <Text style={styles.form_answer_long}>{this.props.experience.Auto_Manual}</Text>
                    </View>
                    <View style={styles.form_field_col}>
                        <Text style={styles.form_text_long}>
                            Do you have experience crossing to the US from Canada in a commercial vehicle?
                        </Text>
                        <Text style={styles.form_answer_long}>
                            {this.props.experience.Cross_US_CA === 'N'
                                ? 'No'
                                : this.props.experience.Cross_US_CA === ''
                                  ? ''
                                  : 'Yes'}
                        </Text>
                    </View>
                    <View style={styles.form_field_col}>
                        <Text style={styles.form_text_long}>Do you have Reefer experience?</Text>
                        <Text style={styles.form_answer_long}>
                            {this.props.experience.Reefer === 'N'
                                ? 'No'
                                : this.props.experience.Reefer === ''
                                  ? ''
                                  : 'Yes'}
                        </Text>
                    </View>
                    <View style={styles.form_field_col}>
                        <Text style={styles.form_text_long}>If yes, how many months/years of Reefer experience?</Text>
                        <Text style={styles.form_answer_long}>{this.props.experience.Months_Years_Reefer}</Text>
                    </View>

                    <View style={styles.form_field_col}>
                        <Text style={styles.form_text_long}>
                            Other type of equipment you have experience with (EX: Van, Tank Flat, Straight Truck etc):
                        </Text>
                        <Text style={styles.form_answer_long}>{this.props.experience.Other_Equipment}</Text>
                    </View>
                    <View style={styles.form_field_col}>
                        <Text style={styles.form_text_long}>
                            List states and provinces operated in the past 5 years:
                        </Text>
                        <Text style={styles.form_answer_long}>{regions_string}</Text>
                    </View>
                </View>
            ) : null;

        //#endregion License Info

        //#region Accidents
        const accident_info =
            this.props.accidents !== undefined ? (
                this.props.accidents.length === 0 ? null : (
                    <Page style={styles.page}>
                        <View style={styles.section}>
                            <Text style={styles.title}>Accidents</Text>
                        </View>
                        {this.props.accidents.map((d, index) => (
                            <View style={styles.section_multi} wrap={false}>
                                <View style={styles.section}>
                                    <Text style={styles.sub_title}>Accident #{index + 1}</Text>
                                </View>
                                <View style={styles.form_field}>
                                    <Text style={styles.form_text}>Accident was reported:</Text>
                                    <Text style={styles.form_answer}>{d.Has_Accidents}</Text>
                                </View>
                                <View style={styles.form_field}>
                                    <Text style={styles.form_text}>Accident Date:</Text>
                                    <Text style={styles.form_answer}>{d.Accident_Date}</Text>
                                </View>
                                <View style={styles.form_field}>
                                    <Text style={styles.form_text}>Were there any fatalities?</Text>
                                    <Text style={styles.form_answer}>
                                        {d.Fatalities === 'N' ? 'No' : d.Fatalities === '' ? '' : 'Yes'}
                                    </Text>
                                </View>
                                <View style={styles.form_field}>
                                    <Text style={styles.form_text}>Was it preventable?</Text>
                                    <Text style={styles.form_answer}>
                                        {d.Preventable === 'N' ? 'No' : d.Preventable === '' ? '' : 'Yes'}
                                    </Text>
                                </View>
                                <View style={styles.form_field}>
                                    <Text style={styles.form_text}>Were there any injuries?</Text>
                                    <Text style={styles.form_answer}>
                                        {d.Injuries === 'N' ? 'No' : d.Injuries === '' ? '' : 'Yes'}
                                    </Text>
                                </View>
                            </View>
                        ))}
                    </Page>
                )
            ) : null;
        //#endregion Accidents

        //#region Traffic Violations
        const traffic_info =
            this.props.traffic_violations !== undefined ? (
                this.props.traffic_violations.length === 0 ? null : (
                    <Page style={styles.page}>
                        <View style={styles.section}>
                            <Text style={styles.title}>Traffic Violations</Text>
                        </View>
                        {this.props.traffic_violations.map((d, index) => (
                            <View style={styles.section_multi} wrap={false}>
                                <View style={styles.section}>
                                    <Text style={styles.sub_title}>Violation #{index + 1}</Text>
                                </View>

                                <View style={styles.form_field}>
                                    <Text style={styles.form_text}>Traffic Violation Date:</Text>
                                    <Text style={styles.form_answer}>{d.Violation_Date}</Text>
                                </View>
                                <View style={styles.form_field}>
                                    <Text style={styles.form_text}>Location:</Text>
                                    <Text style={styles.form_answer}>{d.Location}</Text>
                                </View>
                                <View style={styles.form_field}>
                                    <Text style={styles.form_text}>Charge:</Text>
                                    <Text style={styles.form_answer}>{d.Charge}</Text>
                                </View>
                                <View style={styles.form_field}>
                                    <Text style={styles.form_text}>Penalty:</Text>
                                    <Text style={styles.form_answer}>{d.Penalty}</Text>
                                </View>
                            </View>
                        ))}
                    </Page>
                )
            ) : null;
        //#endregion Traffic Violations

        //#region Application Agreement
        const agreement_signed =
            this.props.application_agreement.Signed === 'Y' ? (
                <View style={styles.section} wrap={false}>
                    <Image src={this.props.signature + '?raw=true'} style={signature_style} />
                    <Text style={datetime_style}>
                        Applicant Signed: {this.props.application_agreement.Signed_Date_Time}
                    </Text>
                </View>
            ) : null;
        const agreement_page =
            this.props.application_agreement !== undefined ? (
                <Page size="A4" style={styles.page}>
                    <View style={styles.section}>
                        <Text style={styles.title}>TO BE READ AND SIGNED BY APPLICANT</Text>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.sub_title}>
                            This certifies that this application was completed by me{' '}
                            {this.props.personal_history.Full_Name} and that all information is true and complete to the
                            best of my knowledge. I authorize you to make such investigations and inquires of my
                            personal employment, financial or medical his and other related matters, as may employers,
                            schools, or persons from all liability in responding to inquire in connection with
                            application. In the event of employment, understand that false or misleading information
                            given in my application may result in discharge. I understand also that I am required to be
                            abide by all rules and regualtions of the company as permitted by law.
                        </Text>
                    </View>
                    <View style={styles.section_multi}>
                        <View style={styles.form_field}>
                            <Text style={styles.form_text}>APPLICANT'S FULL NAME: </Text>
                            <Text style={styles.form_answer}>{this.props.personal_history.Full_Name}</Text>
                        </View>
                    </View>
                    {agreement_signed}
                </Page>
            ) : null;

        //#endregion Application Agreement

        //#region Reference Check
        const reference_signed =
            this.props.reference_check.Signed === 'Y' ? (
                <View style={styles.section} wrap={false}>
                    <Image src={this.props.signature + '?raw=true'} style={signature_style} />
                    <Text style={datetime_style}>Applicant Signed: {this.props.reference_check.Signed_Date_Time}</Text>
                </View>
            ) : null;
        const reference_page =
            this.props.reference_check !== undefined ? (
                <Page size="A4" style={styles.page}>
                    <View style={styles.section}>
                        <Text style={styles.title}>Reference Check</Text>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.sub_title}>{address}</Text>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.sub_title}>
                            The following named person has made an application with our company for the position of AZ
                            Driver:
                        </Text>
                    </View>
                    <View style={styles.section_multi}>
                        <View style={styles.form_field}>
                            <Text style={styles.form_text}>NAME OF APPLICANT: </Text>
                            <Text style={styles.form_answer}>{this.props.personal_history.Full_Name}</Text>
                        </View>
                        <View style={styles.form_field}>
                            <Text style={styles.form_text}>STREET ADDRESS: </Text>
                            <Text style={styles.form_answer}>{this.props.personal_history.Street_Address}</Text>
                        </View>
                        <View style={styles.form_field}>
                            <Text style={styles.form_text}>CITY: </Text>
                            <Text style={styles.form_answer}>{this.props.personal_history.City}</Text>
                        </View>
                        <View style={styles.form_field}>
                            <Text style={styles.form_text}>STATE/PROVINCE: </Text>
                            <Text style={styles.form_answer}>{this.props.personal_history.Province}</Text>
                        </View>
                        <View style={styles.form_field}>
                            <Text style={styles.form_text}>POSTAL CODE: </Text>
                            <Text style={styles.form_answer}>{this.props.personal_history.Postal_Code}</Text>
                        </View>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.sub_title}>
                            I hereby authorize you to release the following information to SCOTLYNN for purpose of
                            investigating as required by Section 391.23 of the Federal Motor Carrier Safety Regulations.
                            You are released from any and all liability, which may result from such information:
                        </Text>
                    </View>
                    {reference_signed}
                </Page>
            ) : null;

        //#endregion Reference Check

        //#region Background Report
        const background_signed =
            this.props.background_check.Signed === 'Y' ? (
                <View style={styles.section} wrap={false}>
                    <Image src={this.props.signature + '?raw=true'} style={signature_style} />
                    <Text style={datetime_style}>Applicant Signed: {this.props.background_check.Signed_Date_Time}</Text>
                </View>
            ) : null;
        const background_page =
            this.props.background_check !== undefined ? (
                <Page size="A4" style={styles.page}>
                    <View style={styles.section}>
                        <Text style={styles.title}>
                            THE BELOW DISCLOSURE AND AUTHORIZATION LANGUAGE IS FOR MANDATORY USE BY ALL ACCOUNTHOLDERS
                        </Text>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.sub_title_big}>IMPORTANT DISCLOSURE</Text>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.sub_title_big}>
                            REGARDING BACKGROUND REPORTS FROM THE PSP Online Service
                        </Text>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.sub_title}>
                            In connection with your application for employment with Scotlynn (“Prospective Employer”),
                            Prospective Employer, its employees, agents or contractors may obtain one or more reports
                            regarding your driving, and safety inspection history from the Federal Motor Carrier Safety
                            Administration (FMCSA)
                        </Text>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.sub_title}>
                            When the application for employment is submitted in person, if the Prospective Employer uses
                            any information it obtains from FMCSA in a decision to not hire you or to make any other
                            adverse employment decision regarding you, the Prospective Employer will provide you with a
                            copy of the report upon which its decision was based and a written summary of your rights
                            under the Fair Credit Reporting Act before taking any final adverse action. If any final
                            adverse action is taken against you based upon your driving history or safety report, the
                            Prospective Employer will notify you that the action has been taken and that the action was
                            based in part or in whole on this report.
                        </Text>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.sub_title}>
                            When the application for employment is submitted by mail, telephone, computer, or other
                            similar means, if the Prospective Employer uses any information it obtains from FMCSA in a
                            decision to not hire you or to make any other adverse employment decision regarding you, the
                            Prospective Employer must provide you within three business days of taking adverse action
                            oral, written or electronic notification: that adverse action has been taken based in whole
                            or in part on information obtained from FMCSA; the name, address, and the toll free
                            telephone number of FMCSA; that the FMCSA did not make the decision to take the adverse
                            action and is unable to provide you the specific reasons why the adverse action was taken;
                            and that you may, upon providing proper identification, request a free copy of the report
                            and may dispute with the FMCSA the accuracy or completeness of any information or report. If
                            you request a copy of a driver record from the Prospective Employer who procured the report,
                            then, within 3 business days of receiving your request, together with proper identification,
                            the Prospective Employer must send or provide to you a copy of your report and a summary of
                            your rights under the Fair Credit Reporting Act.
                        </Text>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.sub_title}>
                            When the application for employment is submitted by mail, telephone, computer, or other
                            similar means, if the Prospective Employer uses any information it obtains from FMCSA in a
                            decision to not hire you or to make any other adverse employment decision regarding you, the
                            Prospective Employer must provide you within three business days of taking adverse action
                            oral, written or electronic notification: that adverse action has been taken based in whole
                            or in part on information obtained from FMCSA; the name, address, and the toll free
                            telephone number of FMCSA; that the FMCSA did not make the decision to take the adverse
                            action and is unable to provide you the specific reasons why the adverse action was taken;
                            and that you may, upon providing proper identification, request a free copy of the report
                            and may dispute with the FMCSA the accuracy or completeness of any information or report. If
                            you request a copy of a driver record from the Prospective Employer who procured the report,
                            then, within 3 business days of receiving your request, together with proper identification,
                            the Prospective Employer must send or provide to you a copy of your report and a summary of
                            your rights under the Fair Credit Reporting Act.
                        </Text>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.sub_title}>
                            Neither the Prospective Employer nor the FMCSA contractor supplying the crash and safety
                            information has the capability to correct any safety data that appears to be incorrect. You
                            may challenge the accuracy of the data by submitting a request to
                            https://dataqs.fmcsa.dot.gov. If you challenge crash or inspection information reported by a
                            State, FMCSA cannot change or correct this data. Your request will be forwarded by the
                            DataQs system to the appropriate State for adjudication.
                        </Text>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.sub_title}>
                            Any crash or inspection in which you were involved will display on your PSP report. Since
                            the PSP report does not report, or assign, or imply fault, it will include all Commercial
                            Motor Vehicle (CMV) crashes where you were a driver or co-driver and where those crashes
                            were reported to FMCSA, regardless of fault. Similarly, all inspections, with or without
                            violations, appear on the PSP report. State citations associated with Federal Motor Carrier
                            Safety Regulations (FMCSR) violations that have been adjudicated by a court of law will also
                            appear, and remain, on a PSP report.
                        </Text>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.sub_title}>
                            The Prospective Employer cannot obtain background reports from FMCSA without your
                            authorization.
                        </Text>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.sub_title_big}>AUTHORIZATION</Text>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.sub_title}>
                            If you agree that the Prospective Employer may obtain such background reports, please read
                            the following and sign below:
                        </Text>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.sub_title}>
                            I authorize (“Prospective Employer”) Scotlynn to access the FMCSA Pre-Employment Screening
                            Program (PSP) system to seek information regarding my commercial driving safety record and
                            information regarding my safety inspection history. I understand that I am authorizing the
                            release of safety performance information including crash data from the previous five (5)
                            years and inspection history from the previous three (3) years. I understand and acknowledge
                            that this release of information may assist the Prospective Employer to make a determination
                            regarding my suitability as an employee.
                        </Text>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.sub_title}>
                            I further understand that neither the Prospective Employer nor the FMCSA contractor
                            supplying the crash and safety information has the capability to correct any safety data
                            that appears to be incorrect. I understand I may challenge the accuracy of the data by
                            submitting a request to https://dataqs.fmcsa.dot.gov. If I challenge crash or inspection
                            information reported by a State, FMCSA cannot change or correct this data. I understand my
                            request will be forwarded by the DataQs system to the appropriate State for adjudication.
                        </Text>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.sub_title}>
                            I understand that any crash or inspection in which I was involved will display on my PSP
                            report. Since the PSP report does not report, or assign, or imply fault, I acknowledge it
                            will include all CMV crashes where I was a driver or codriver and where those crashes were
                            reported to FMCSA, regardless of fault. Similarly, I understand all inspections, with or
                            without violations, will appear on my PSP report, and State citations associated with FMCSR
                            violations that have been adjudicated by a court of law will also appear, and remain, on my
                            PSP report.
                        </Text>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.sub_title}>
                            I have read the above Disclosure Regarding Background Reports provided to me by Prospective
                            Employer and I understand that if I sign this Disclosure and Authorization, Prospective
                            Employer may obtain a report of my crash and inspection history. I hereby authorize
                            Prospective Employer and its employees, authorized agents, and/or affiliates to obtain the
                            information authorized above.
                        </Text>
                    </View>
                    <View style={styles.section_multi}>
                        <View style={styles.form_field}>
                            <Text style={styles.form_text}>Name (Please Print): </Text>
                            <Text style={styles.form_answer}>{this.props.personal_history.Full_Name}</Text>
                        </View>
                    </View>
                    {background_signed}
                    <View style={styles.section}>
                        <Text style={styles.sub_title}>
                            NOTICE: This form is made available to monthly account holders by NIC on behalf of the U.S.
                            Department of Transportation, Federal Motor Carrier Safety Administration (FMCSA). Account
                            holders are required by federal law to obtain an Applicant’s written or electronic consent
                            prior to accessing the Applicant’s PSP report. Further, account holders are required by
                            FMCSA to use the language contained in this Disclosure and Authorization form to obtain an
                            Applicant’s consent. The language must be used in whole, exactly as provided. Further, the
                            language on this form must exist as one stand-alone document. The language may NOT be
                            included with other consent forms or any other language.
                        </Text>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.sub_title}>
                            NOTICE: The prospective employment concept referenced in this form contemplates the
                            definition of “employee” contained at 49 C.F.R. 383.5.
                        </Text>
                    </View>
                </Page>
            ) : null;

        //#endregion Background Report

        const application_document = (
            <Document title="Scotlynn Driver Application" style={styles.document}>
                {general_info_page}
                {truck_info_page}
                <Page size="A4" style={styles.page}>
                    {personal_info_page}
                    {emergency_contact_info}
                </Page>
                <Page size="A4" style={styles.page}>
                    {physical_info}
                </Page>
                {employers}
                <Page size="A4" style={styles.page}>
                    {license}
                    {experience_info}
                </Page>
                {accident_info}
                {traffic_info}
                {agreement_page}
                {reference_page}
                {background_page}
            </Document>
        );

        //#region Application Validation
        let personal_validated = false;
        if (this.props.personal_history) {
            if (
                this.props.personal_history.City &&
                this.props.personal_history.Province &&
                this.props.personal_history.Cell_Phone
            ) {
                personal_validated = true;
            } else {
                personal_validated = false;
            }
        } else {
            personal_validated = false;
        }

        let employment_validated = false;
        if (this.props.current_application) {
            if (
                this.props.current_application.Employment_Completed === 'Y' ||
                this.props.current_application.Employment_Completed === 'U'
            ) {
                employment_validated = true;
            } else {
                employment_validated = false;
            }
        }

        //#endregion Application Validation

        return (
            <div className="d-flex flex-column">

                <ToastContainer position="top-center" limit={1} />
                {this.props.current_application.Date_Submitted !== '1/1/1900 12:00:00 AM' &&
                this.props.current_application.Date_Submitted !== '' ? (
                    <div className="d-flex flex-column">
                        <h3 className="mb-4">Thank you for submitting your application!</h3>
                        <h5 className="mb-4">A recruiter will be in touch with you soon.</h5>
                        <h5 className="mb-4">
                            If you wish to contact recruiting now please call
                            <a href="tel:+1-800-263-9117,2536" className="submission-link px-2">
                                1-800-263-9117 x 2536
                            </a>
                            or email
                            <a className="submission-link px-2" href="mailto:recruiting@scotlynn.com">
                                recruiting@scotlynn.com
                            </a>
                        </h5>
                        <PDFDownloadLink
                            document={application_document}
                            fileName={this.props.current_application.Full_Name + '.pdf'}
                            style={{
                                marginBottom: '2rem',
                                fontSize: '1.2rem',
                                textUnderlinePosition: 'under',
                                marginTop: '3rem',
                            }}
                        >
                            {({ blob, url, loading, error }) =>
                                loading
                                    ? 'Loading document...'
                                    : 'Click here to download a copy of your completed application'
                            }
                        </PDFDownloadLink>
                    </div>
                ) : this.props.loading === 'T' ? (
                    <h6 className="text-muted">Loading document...</h6>
                ) : (
                    <div className="d-flex flex-column">
                        <h5 className="mb-5">Ready to submit your application?</h5>

                        <div className="d-flex flex-row mx-auto mb-3" style={{ justifyContent: 'space-around' }}>
                            <Button
                                variant="outline-secondary"
                                onClick={() => this.props.onBack()}
                                style={{ alignSelf: 'center', width: '180px' }}
                                className="btn-op70 me-3"
                            >
                                Back
                            </Button>
                            <Button
                                variant={sending ? 'light' : 'success'}
                                className="btn-op70"
                                style={{ alignSelf: 'center', width: '180px' }}
                                disabled={sending || !personal_validated || !employment_validated}
                                onClick={async () => await this.sendApplication(application_document)}
                            >
                                {sending ? 'Submitting...' : 'Submit Application'}
                            </Button>
                        </div>

                        {personal_validated ? null : (
                            <Alert variant="danger" className="my-3">
                                You are missing required fields from
                                <Button
                                    variant="link"
                                    onClick={() => this.props.set_view_page('personal')}
                                    className="text-underlined text-primary p-0 ps-1 pb-1 btn-op70"
                                >
                                    Personal History
                                </Button>
                                . Please complete these fields before submitting your application.
                            </Alert>
                        )}
                        {employment_validated ? null : (
                            <Alert variant="danger" className="my-3">
                                You have not completed
                                <Button
                                    variant="link"
                                    onClick={() => this.props.set_view_page('employers')}
                                    className="text-underlined text-primary p-0 ps-1 pb-1 btn-op70"
                                >
                                    Employment History
                                </Button>
                                . Please complete this section before submitting your application.
                            </Alert>
                        )}
                    </div>
                )}
            </div>
        );
    }
}
