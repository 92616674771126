async function SendMicrosoftEmail(options) {
    fetch('https://graph.microsoft.com/v1.0/me/sendMail', options)
        .then((o) => o)
        .catch((error) => {
            return 'open issue';
        });
}

function CreateMessageBody(subject_line, body_text, recipients) {
    return JSON.stringify({
        message: {
            subject: subject_line,
            body: {
                contentType: 'Text',
                content: body_text,
            },
            toRecipients: recipients,
        },
    });
}
async function sendPostFetch(body, fetchString) {
    try {
        const fetchResponse = await fetch(fetchString, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: body,
        });
        const data = await fetchResponse.json();
        return data;
    } catch (e) {
        return 'open issue';
    }
}

async function sendPutFetch(body, fetchString) {
    try {
        const fetchResponse = await fetch(fetchString, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: body,
        });
        const data = await fetchResponse.json();
        return data;
    } catch (e) {
        return 'open issue';
    }
}

async function sendGetFetch(fetchString) {
    try {
        const response = await fetch(fetchString);
        const data = await response.json();
        return data;
    } catch (e) {
        return 'open issue';
    }
}

async function sendDeleteFetch(body, fetchString) {
    try {
        const fetchResponse = await fetch(fetchString, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: body,
        });
        const data = await fetchResponse.json();
        return data;
    } catch (e) {
        console.log(e);
        return 'open issue';
    }
}

export { sendPutFetch, sendGetFetch, sendDeleteFetch, SendMicrosoftEmail, CreateMessageBody, sendPostFetch };
